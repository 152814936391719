/* eslint-disable no-magic-numbers */
import React, { useEffect, useState, useMemo, useRef } from 'react'
import { Button, Toast, Popup } from 'antd-mobile'
import styles from './authorize-commom-page.module.scss'
import Header from '@/components/header/header'
import classNames from 'classnames'
import ProductList from '@/components/product-list/product-list'
import productApi, { ProductListResponse, ProductListParams } from '@/apis/product'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { isIOS, isAndroid, isInWeChat, isMobile, download, Storage } from '@bihu/common-js'
import activityApi from '@/apis/activity'
import copy from 'copy-to-clipboard'
import { ENTER_AGREEMENT, TOKEN } from '@/constants/storage'
import indexApi from '@/apis'
import ProtocolIdsConfirm1 from '../protocolIds-confirm-1/protocolIds-confirm-1'
import ProtocolIdsConfirm2 from '../protocolIds-confirm-2/protocolIds-confirm-2'
import useFirstOrAfter from '@/hooks/use-first-or-after'
import ApiToH5Skin from './components/api-to-h5-skin/api-to-h5-skin'
import { selectUserInfo } from '@/store/user'
import { useAppSelector } from '@/store/hooks'

interface Props{}


const Authorize:React.FC<Props> = () => {
  const ProtocolIdsConfirm1ModalRef: any = useRef()
  const ProtocolIdsConfirm2ModalRef: any = useRef()
  const [search] = useSearchParams()
  const userInfo = useAppSelector(selectUserInfo)

  const [details, setDetails] = useState<any>({})
  const [rqResult, setRqResult] = useState<any>()
  const [otherProtocols, setotherProtocols] = useState([])
  const [youqianlaiProtocol, setYouqianlaiProtocol] = useState<any>('')
  const [agreementContent, setAgreementContent] = useState('')

  const navigate = useNavigate()

  useFirstOrAfter(() => {
    localStorage.removeItem('productBack')

    if (!Storage.get('isRefreshLoadPage') && !search.get('refresh')) {
      Storage.set('isRefreshLoadPage', true)
      // window.location.href = `${document.URL}&refresh=1`
      return
    } else {
      Storage.set('isRefreshLoadPage', false)
    }

    // 监听浏览器回退事件
    window.addEventListener('popstate', back, false)
    // window.onpopstate = back
    window.history.pushState(null, 'null', document.URL)

    return () => {
      // 回退事件只用于当前组件，则需要在组件销毁时把回退事件销毁
      window.removeEventListener('popstate', back, false)
      // window.onpopstate = null
    }
  }, () => {
  // eslint-disable-next-line no-console
    console.log('afterFn')
  }, [])

  // 打开挽留弹窗
  const openProtocolIdsConfirm1Modal = () => {
    let tempYouqianlaiProtocol = ''
    let temPotherProtocols:any = []
    // eslint-disable-next-line array-callback-return
    rqResult.protocols.map((item:any) => {
      if (item.protocolType !== 1) {
        temPotherProtocols.push(item)
      } else if (item.protocolType === 1) {
        tempYouqianlaiProtocol = item
      }
    })
    ProtocolIdsConfirm1ModalRef.current.init({
      quota: details.applyLimit >= 3 ? '100,000' : '50,000',
      productName: rqResult.productName,
      youqianlaiProtocol: tempYouqianlaiProtocol,
      otherProtocols: temPotherProtocols,
      productId: rqResult.id,
    })
  }


  // 返回
  const back = () => {
    const isShowPopupStorage = Storage.get('isShowPopup')

    try {
      if (isShowPopupStorage) {
        Storage.set('isShowPopup', false)
        navigate(-1)
      } else {
        //  阻止浏览器回退事件
        openProtocolIdsConfirm1Modal()
        Storage.set('isRefreshLoadPage', false)
        // window.history.pushState(null, 'null', document.URL)
        // localStorage.setItem('productBack', formatTime(Date.now(), 'YYYY-MM-DD'))
        Storage.set('isShowPopup', true)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      // console.log(error)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  const [token, setToken] = useState('')
  const [apiChannelCode, setApiChannelCode] = useState('')
  const [serialNo, setSerialNo] = useState('')
  const [isSpecialChannel, setIsSpecialChannel] = useState<boolean>(false)
  const [jumpUrl, setJumpUrl] = useState('')
  // 当前操作第几回授权
  const [authNum, setAuthNum] = useState(0)
  let urlRqResult:any = search.get('rqResult')
  useEffect(() => {
    let urlApiChannelCode:any = search.get('apiChannelCode')
    let urlSerialNo:any = search.get('serialNo')
    let urlToken:any = search.get('token')
    let urlJumpUrl:any = search.get('jumpUrl')
    //设置authNum
    let urlAuthNum:any = search.get('authNum')
    if (urlAuthNum) {
      setAuthNum(Number(urlAuthNum))
    }
    if (urlApiChannelCode) {
      setSerialNo(urlSerialNo)
      setApiChannelCode(urlApiChannelCode)
      localStorage.setItem('ad_channel_code', urlApiChannelCode)
      if (urlJumpUrl) {
        const tempJumpUrl = JSON.parse(decodeURIComponent(urlJumpUrl))
        setJumpUrl(tempJumpUrl)
      }
      if (urlApiChannelCode === 'kxj01' || urlApiChannelCode === 'kxj02') {
        setIsSpecialChannel(true)
      }
      if (urlToken) {
        Storage.set(TOKEN, urlToken)
        setToken(urlToken)
      }
    } else {
      const pageOptions:any = search.get('options')
      const tempDetails = JSON.parse(decodeURIComponent(pageOptions)) || {}
      console.log('~~~', tempDetails)
      setDetails(tempDetails)
      setRqResult(tempDetails.rqResult)
    }
  }, [])

  // 应用在API采量授权页重刷的场景
  const reVisitApiAuthPage = () => {
    if (urlRqResult) {
      const tempRqResult = JSON.parse(decodeURIComponent(urlRqResult))
      setRqResult(tempRqResult)
      setDetails({
        applyLimit: tempRqResult.applyLimit
      })
    }
  }

  useEffect(() => {
    let newProtocols:any = []
    // eslint-disable-next-line array-callback-return
    rqResult && rqResult.protocols.map((item:any, index:number) => {
      if (item.protocolType !== 1) {
        newProtocols.push(item)
      } else if (item.protocolType === 1) {
        setYouqianlaiProtocol(item)
      }
    })

    // 针对星辰钱包全流程类型的合作方式，需要把第三方协议并入合作机构协议中展示
    if (rqResult && (rqResult.thirdpartyType === 7 || rqResult.thirdpartyType === 2)) {
      if (rqResult.yqlWholeProcessBumpVO && rqResult.yqlWholeProcessBumpVO.partnerProtocolList) {
        newProtocols = [
          ...newProtocols,
          ...rqResult.yqlWholeProcessBumpVO.partnerProtocolList
        ]
      }
    }
    setotherProtocols(newProtocols)
  }, [rqResult])

  useEffect(() => {
    async function getAgreementInfo() {
      const res:any = await indexApi.getAgreementInfo(youqianlaiProtocol.id, rqResult.id)
      setAgreementContent(res.content)
    }

    if (youqianlaiProtocol) {
      getAgreementInfo()
    }

  }, [youqianlaiProtocol])

  const toHuiYiHuaPage = (res:any) => {
    if (apiChannelCode) {
      const tempUrl = `/fill-information/authorize?options=${encodeURIComponent(JSON.stringify(res))}&apiChannelCode=${apiChannelCode}&applyLimit=${res.applyLimit}&jumpUrl=${encodeURIComponent(JSON.stringify(jumpUrl))}&serialNo=${serialNo}`
      window.location.replace(tempUrl)
    } else {
      window.location.replace(`/fill-information/authorize?options=${encodeURIComponent(JSON.stringify(res))}&adChannelCode=${details.adChannelCode}&isHuaWeiPhone=${details.isHuaWeiPhone}&applyLimit=${res.applyLimit}`)
    }
  }

  useEffect(() => {
    async function getFomrSubmitStatus() {
      const res:any = await indexApi.getFomrSubmitStatus({ adChannelCode: apiChannelCode, serialNo })
      // 如果用户已经授权过，并且第二回授权没有结果，跳转到上回结果页
      if (res.submitStatus === 10 && res.rematchResult !== 3) {
        const reMatchResult:any = res
        let tempOptions:any = {}
        // 否则取上回的结果，跳至下载页
        tempOptions = JSON.parse(JSON.stringify(reMatchResult))
        tempOptions.isNotFormReq = true // 标记为非表单请求方式的跳转
        tempOptions.rePageShowType = 0
        tempOptions.revisit = true // 标记为再访
        window.location.replace(`/fill-information/success?options=${encodeURIComponent(JSON.stringify(tempOptions))}`)

        return
      }
      // 判断是否匹配回调类型(如：惠逸花、惠逸花A)
      if (res.thirdpartyType === 5) {
        toHuiYiHuaPage(res)
        return
      }

      setDetails({
        applyLimit: res.applyLimit
      })
      setRqResult(res)
    }

    // 说明当前路由已被修改，从URl中获取修改后的数据
    if (apiChannelCode && authNum > 0) {
      reVisitApiAuthPage()
    } else if (token && apiChannelCode) {
      getFomrSubmitStatus()
      let devicePlatForm:any = 3
      if (isAndroid) {
        devicePlatForm = 1
      }
      if (isIOS) {
        devicePlatForm = 2
      }
      indexApi.reportPhoneType(devicePlatForm)
    }

  }, [token, apiChannelCode, serialNo])


  // 渠道编码
  const [adChannelCode, setAdChannelCode] = useState('')

  useEffect(() => {
    const code = search.get('adChannelCode')
    if (code) {
      setAdChannelCode(code)
      localStorage.setItem('ad_channel_code', code)
    }
  }, [])

  // 选择协议
  const [checked, setChecked] = useState(false)

  // 切换选中协议
  const checkedTaggle = () => {
    setChecked(!checked)
  }

  // 按钮loading
  const [btnLoading, setBtnLoading] = useState(false)

  // 跳转页面
  const openOtherView = (url: string) => {
    Storage.set(ENTER_AGREEMENT, true)
    window.location.href = url
  }

  const submitApplication = async(isNeedChecked = true) => {
    try {
      if (!checked && isNeedChecked) {
        ProtocolIdsConfirm2ModalRef.current.init({
          youqianlaiProtocol,
          otherProtocols,
          productId: rqResult.id,
        })
        return
      }
      let protocolIds = rqResult.protocols.map((item:any) => {
        return item.id
      })

      // 如果匹配到的是H5产品，则走H5产品的申请、联登流程
      if (rqResult.thirdpartyType === 1) {
        handleH5Product(rqResult.id, 0, protocolIds)
        return
      }

      //获取在登录页时保存的百度渠道的全链接
      const bdVidUrl = localStorage.getItem('baiduVistLink') || ''
      setBtnLoading(true)
      // 前端日志上报
      const reportParam = `uid:${userInfo?.uid},adChannelCode:${details.adChannelCode || apiChannelCode},event:授权点击`
      indexApi.reportLog(reportParam)
      // 信息授权
      const res:any = await indexApi.confirmAuthorize({
        productId: rqResult.id,
        protocolIds,
        agree: true,
        adChannelCode: details.adChannelCode,
        isHuaWeiPhone: details.isHuaWeiPhone,
        apiChannelCode,
        bdVidUrl,
        orderNum: authNum, //第几回授权
        serialNo: rqResult.serialNo || serialNo
      })
      setBtnLoading(false)
      handleAduitSuccess(res)
    } catch (error) {
      setBtnLoading(false)
    }
  }

  // 处理H5产品逻辑
  const handleH5Product = async(productId:any, entranceType:number, protocolIds:any) => {
    // 申请产品
    let applyProductRes:any = await indexApi.applyFor({ productId, entranceType, appH5: 2 })
    // 判断是否需要联登
    if (applyProductRes.unionLoginFlag === 1) {
      try {
        const authH5ProductRes:any = await indexApi.authH5Product({
          productId,
          protocolIds: `${protocolIds}${''}`,
          agree: true,
          entranceType,
          appH5: 2
        })
        if (authH5ProductRes) {
          window.location.href = authH5ProductRes
        } else {
          window.location.href = `/fill-information/success?options=${encodeURIComponent(JSON.stringify({ rePageShowType: details.adChannelCode === '9o0w2B' && details.isHuaWeiPhone ? 2 : 0 }))}`
        }
      } catch (error) {
        window.location.href = `/fill-information/success?options=${encodeURIComponent(JSON.stringify({ rePageShowType: details.adChannelCode === '9o0w2B' && details.isHuaWeiPhone ? 2 : 0 }))}`
      }
    } else {
      window.location.href = applyProductRes.thirdpartyTargetUrl
    }
  }

  // 修改页面url
  const reFreshPageUrl = (newRqResult:any, tempAuthNum: any) => {
    const pageUrl = window.location.origin + window.location.pathname
    let urlOptions = ''
    // 如果是API采量授权方式
    if (apiChannelCode) {
      let modifiedUrl = `${pageUrl}?apiChannelCode=${apiChannelCode}&jumpUrl=${encodeURIComponent(JSON.stringify(jumpUrl))}&authNum=${tempAuthNum}&rqResult=${encodeURIComponent(JSON.stringify(newRqResult))}&serialNo=${serialNo}`
      window.history.replaceState(null, 'null', modifiedUrl)
    } else {
      urlOptions = {
        ...details,
        rqResult: newRqResult,
      }

      window.history.replaceState(null, 'null', `${pageUrl}?options=${encodeURIComponent(JSON.stringify(urlOptions))}&authNum=${tempAuthNum}`)
    }
  }

  const handleAduitSuccess = async(res:any) => {
    // 正常授权且有结果
    if (res && res.applyStatus !== 2) {
      // 如果url上有携带跳转地址jumpUrl，则跳转
      if (jumpUrl) {
        res.jumpUrl = jumpUrl
        window.location.href = `/fill-information/success-transfer?options=${encodeURIComponent(JSON.stringify(res))}`
        return
      }
      // 如果是API转H5类型，则直接跳转第三方链接
      if (res.thirdpartyType === 4 && res.thirdpartyTargetUrl) {
        await indexApi.apiH5JumpUrlSuccessFlag({
          uid: userInfo?.uid,
          productId: rqResult.id,
          productName: rqResult.productName,
          thirdpartyTargetUrl: res.thirdpartyTargetUrl
        })
        window.location.href = res.thirdpartyTargetUrl
        return
      }

      // 如果授权失败，但是有返回下一个产品，就重新授权
      if (res.applyStatus === 4 && res.productNextMatchDTO) {
        // productNextMatchDTO没有返回serialNo，需要保存
        if (rqResult.serialNo) {
          res.productNextMatchDTO.serialNo = rqResult.serialNo
        }
        // 判断是否匹配回调类型(如：惠逸花、惠逸花A)
        if (res.productNextMatchDTO.thirdpartyType === 5) {
          toHuiYiHuaPage(res.productNextMatchDTO)
          return
        }
        // 如果是其它产品，则更新本页面信息
        setRqResult(res.productNextMatchDTO)
        setAuthNum(res.productNextMatchDTO.orderNum)
        Toast.show('申请名额已满，您的匹配机构已更新，请继续操作')
        reFreshPageUrl(res.productNextMatchDTO, res.productNextMatchDTO.orderNum)
        return
      }

      window.location.href = `/fill-information/success?options=${encodeURIComponent(JSON.stringify(res))}`
      return
    }

    // 结果页
    // 走这里说明是重复授权或者授权失败，重复授权也需要判断res
    const options:any = res ? res : { rePageShowType: details.adChannelCode === '9o0w2B' && details.isHuaWeiPhone ? 2 : 0 }
    window.location.href = `/fill-information/success?options=${encodeURIComponent(JSON.stringify(options))}`
  }
  const handleLoginSuccess = (res:any) => {
    //
  }
  const handelSubmitData = () => {
    setChecked(true)
    submitApplication(false)
  }

  // 弹起协议相关列表
  const openProtocolsPopup = () => {
    setProtocolIdsVisible(true)
  }

  // 是否显示弹出层
  const [protocolIdsVisible, setProtocolIdsVisible] = useState(false)

  // 是否显示弹出层
  const [monthVisible, setMonthVisible] = useState(false)
  // 当前选中月份
  // eslint-disable-next-line no-magic-numbers
  const [currentMonth, setCurrentMonth] = useState<number>(12)

  // 月份数组
  const monthArr = [
    {
      key: 1,
      num: 3,
    },
    {
      key: 2,
      num: 6,
    },
    {
      key: 3,
      num: 9,
    },
    {
      key: 4,
      num: 12,
    },
  ]

  // 选择月份
  const selectMonth = (num: number) => {
    setCurrentMonth(num)
    setMonthVisible(false)
  }

  // 倒计时
  const [countdownTime, setCountdownTime] = useState<number>(5)
  const timer = useRef<ReturnType<typeof setTimeout>>()

  // 倒计时
  useEffect(() => {
    if (isMobile) {
      if (countdownTime > 0) {
        timer.current = setTimeout(() => {
          setCountdownTime(countdownTime - 1)
        }, 1000)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdownTime])

  const checkOtherProtocols = (item: any) => {
    if (item.id) {
      openOtherView(`/info-audit-agreement?isShowHeader=true&agreementId=${item.id}&title=${item.name}&productId=${rqResult.id}`)
    } else if (item.url) {
      window.location.href = item.url
    }
  }

  return (
    <section className={styles.authorizePage}>
      {/* <Header title="星辰钱包" /> */}
      <div>
        {
          rqResult?.thirdpartyType && rqResult?.thirdpartyType !== 5 ? <>
            {
              apiChannelCode ? <>
                <ApiToH5Skin
                  rqResult={rqResult ? rqResult : {}}
                  agreementContent={agreementContent}
                  youqianlaiProtocolName={youqianlaiProtocol?.name}
                  isSpecialChannel={isSpecialChannel}
                />
              </> : <>
                {
                  rqResult?.thirdpartyType && <div>
                    <div className={styles.headerBox}>
                      <img className={styles.headerIcon} src={require('@imgs/information/umbrella2.png')} />
                      <div className={styles.headerContent}>严选正规机构100+，星辰钱包为您匹配低息借款方案</div>
                    </div>
                    <div className={styles.mainContent}>
                      <div className={styles.limitBox}>
                        <div className={styles.limitBoxTitle}>最高可借额度（元）</div>
                        <div className={styles.limitNum}>{ details?.applyLimit >= 3 ? '100,000' : '50,000'}</div>
                        <div className={styles.splitLine}></div>
                        <div className={styles.limitBoxFooter}>
                          <div className={styles.footerItem}>
                            <img className={styles.footerItemIcon} src={require('@imgs/information/ic_safe-1.png')} />
                            <span>年化利率7.2%起</span>
                          </div>
                          <div className={styles.footerItem}>
                            <img className={styles.footerItemIcon} src={require('@imgs/information/ic_safe-2.png')} />
                            <span>随时可还款</span>
                          </div>
                          <div className={styles.footerItem}>
                            <img className={styles.footerItemIcon} src={require('@imgs/information/ic_safe-3.png')} />
                            <span>额度灵活</span>
                          </div>
                        </div>
                      </div>
                      <div className={styles.loanDurationBox} onClick={() => setMonthVisible(true)}>
                        <div>借多久</div>
                        <div className={styles.loanDurationBoxRight}>
                          { currentMonth }个月
                          <img className={styles.arrowBlack} src={require('@imgs/information/arrow-black.png')} />
                        </div>
                      </div>
                      <div className={styles.productBox}>
                        <div className={styles.productTitle}>
                          <div className={styles.titleLine}></div>
                          为您匹配
                        </div>
                        <div className={styles.splitLine}></div>
                        <div className={styles.productNameBar}>
                          <img className={styles.productIcon} src={require('@imgs/information/product.png')} />
                          <span>{ rqResult && rqResult.productName }</span>
                        </div>
                        <div className={styles.companyBox}>
                          <img className={styles.companyIcon} src={require('@imgs/information/company.png')} />
                          <span>{ rqResult && rqResult.productCompany }</span>
                        </div>
                      </div>
                      <div className={styles.protocolBox}>
                        <div className={styles.specialTips}>
                          特别提示：我们会根据您的资质，为您匹配专业的贷款咨询服务机构，请您查阅《个人信息共享授权协议》
                        </div>
                        <div className={styles.protocolBoxContent}>
                          <div className={styles.protocolBoxTitle}>{ youqianlaiProtocol.name }</div>
                          <div dangerouslySetInnerHTML={{ __html: agreementContent }}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </>
            }
            <div className={styles.footerBox}>
              <div className={styles.footerText}>
                {
                  checked ? <>
                    <img onClick={checkedTaggle} className={styles.checkedIcon} src={require('@imgs/information/checked.png')} />
                  </> : <>
                    <div className={classNames(styles.checked)} onClick={checkedTaggle}></div>
                  </>
                }
                <div>
                  <span onClick={checkedTaggle}>同意并签署</span>
                  <span
                    className={styles.footerTextRed}
                    onClick={() => {
                      openOtherView(`/info-audit-agreement?isShowHeader=true&agreementId=${youqianlaiProtocol.id}&title=${youqianlaiProtocol.name}&productId=${rqResult.id}`)
                    }}
                  >
                《{youqianlaiProtocol.name}》
                  </span>
                  {
                    otherProtocols.length > 0 && <span
                      className={styles.footerTextRed}
                      onClick={() => {
                        openProtocolsPopup()
                      }}
                    >
                    《机构相关协议》
                    </span>
                  }
                </div>
              </div>
              <div className={styles.btnBox}>
                <Button block loading={btnLoading} loadingText="请等待机构申请结果" className={styles.btn} shape="rounded" color="primary" onClick={() => submitApplication(true)}>
                  { `立即领取额度${countdownTime ? `（${countdownTime}s）` : ''}`}
                </Button>
              </div>
              <div className={styles.paddingBox}></div>
            </div>
          </> : <>
            <div id="root">
              <div id="loading-box">
                <div className={styles.ballClipRotateMultiple}>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          </>
        }
      </div>
      <Popup
        visible={monthVisible}
        onMaskClick={() => {
          setMonthVisible(false)
        }}
      >
        <ul className={styles.monthBox}>
          {
            monthArr.map(item => {
              return <li
                key={item.key}
                className={classNames(styles.row, item.num === currentMonth && styles.active)}
                onClick={() => selectMonth(item.num)}
              >
                {item.num} 个月
              </li>
            })
          }
        </ul>
      </Popup>

      <Popup
        visible={protocolIdsVisible}
        showCloseButton={true}
        bodyStyle={{
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
          minHeight: '40vh',
        }}
        onMaskClick={() => {
          setProtocolIdsVisible(false)
        }}
        onClose={() => {
          setProtocolIdsVisible(false)
        }}
      >
        <div className={styles.protcolPopup}>
          <div className={styles.popupTitle}>协议列表</div>
          <div className={styles.protocolIdsBox}>
            {
              otherProtocols.map((item:any) => {
                return (
                  <div
                    className={styles.protocolIdItem}
                    onClick={() => checkOtherProtocols(item)}
                    key={item.name}
                  >
                    <div>{ `《${item.name}》` }</div>
                    <img className={styles.arrowBlack} src={require('@imgs/information/arrow-black.png')} />
                  </div>
                )
              })
            }
          </div>
        </div>
      </Popup>

      {/* 挽留弹窗 */}
      <ProtocolIdsConfirm1
        ref={ProtocolIdsConfirm1ModalRef}
        onSuccess={(e:any) => handleLoginSuccess(e)}
        openProtocolsPopup={() => openProtocolsPopup()}
        submit={() => handelSubmitData()}/>

      {/* 同意协议弹窗 */}
      <ProtocolIdsConfirm2
        ref={ProtocolIdsConfirm2ModalRef}
        onSuccess={(e:any) => handleLoginSuccess(e)}
        openProtocolsPopup={() => openProtocolsPopup()}
        submit={() => handelSubmitData()}/>

    </section>
  )
}

export default Authorize